<template>
	<div>
		<h4 class="h4_title">{{translate('gold_input_information')}}</h4>
		<van-cell-group>
			<van-cell :title="translate('info_amount_of_deposit')" :value="total" />
			<van-cell :title="translate('method_of_deposit')" :value="method" />
			<van-cell :title="translate('current_status')">
				<template #right-icon>
					<span style="color: grey;" v-if="status == 1">{{translate('info_to_be_transferred')}}</span>
					<span style="color: steelblue;" v-if="status == 2">{{translate('info_have_been_successful')}}</span>
					<span style="color: red;" v-if="status == 3">{{translate('info_has_been_cancelled')}}</span>
					<span style="color: blue;" v-if="status == 4">{{translate('receipt_has_been_uploaded')}}</span>
					<span style="color: steelblue;" v-if="status == 5">{{translate('info_reviewed_and_approved')}}</span>
				</template>
			</van-cell>
			<van-cell :title="translate('reason_for_cancellation')" :value="reason" v-show="reason" />
			<van-cell :title="translate('date_of_submission')" :value="date" />
		</van-cell-group>

		<div v-if="deposit_info">
			<h4 class="h4_title">{{translate('transfer_information')}}</h4>
			<div style="background-color: #FFFFFF;padding: 15px;line-height:190%;color: #555" v-html="deposit_info"></div>
		</div>

		<h4 class="h4_title">{{translate('receipt_uploading')}}</h4>
		
		<div style="text-align: center;background-color: #FFFFFF;padding: 10px 0;">
			<van-image width="140" height="140" :src="image" style="vertical-align: middle;" @click="imageUpload()" v-if="status == 1 || status == 4"/>

			<van-image width="140" height="140" :src="image" style="vertical-align: middle;" v-else/>
		</div>

		<p style="font-size:12px;color: #666;padding: 5px 16px">{{translate('please_ensure_deposit_filled')}}</p>

		<div class="common_block" v-if="status == 1 || status == 4">
			<van-button type="warning" block :loading="submitLoading" @click="submitImage">{{translate('confirm_upload_submission')}}</van-button>
		</div>

		<div class="common_block" v-if="status == 1 || status == 4">
			<van-button type="danger" block :loading="cancelLoading" @click="cancelDeposit">{{translate('cancellation_of_deposit')}}</van-button>
		</div>

	</div>
</template>

<script>
	export default {
		name: 'deposit_info',
		data() {
			return {
				formItem: {
					id: "",
					token: "",
					image: ""
				},

				submitLoading: false,
				cancelLoading: false,

				total: "",
				amount: "",
				method: "",
				date: "",
				status: "",
				reason: "",
				image: "",

				deposit_info: ""
			}
		},
		mounted() {
			if (this.$route.query.token == "" || this.$route.query.token == undefined) {
				this.$router.replace('/nologin')
			} else {
				this.formItem.token = this.$route.query.token
				this.formItem.id = this.$route.query.id
				
				this.loadData()
			}
			
			window.set_pic = this.set_pic
		},
		methods: {
			loadData() {
				this.$toast.loading({
					message: this.translate('loading'),
					forbidClick: true,
				})
				
				this.$axios({
					method: 'post',
					url: 'cash_deposit/info', 
					data: this.formItem
				}).then ((res) => {
					
					this.$toast.clear()
					
					if (res.success) {
						this.total = res.data.total
						this.amount = res.data.amount
						this.method = res.data.method
						this.date = res.data.date
						this.status = res.data.status
						this.image = res.data.image
						this.reason = res.data.reason
						this.deposit_info = res.data.deposit_info
					} else {
						this.$dialog.alert({
							message: res.error_text
						})
					}
				})
			},
			cancelDeposit() {
				this.$dialog.confirm({
					message: this.translate('sure_cancel_the_deposit')
				}).then(() => {

					this.cancelLoading = true

					this.$toast.loading({
						message: this.translate('please_wait'),
						forbidClick: true,
					})
					
					this.$axios({
						method: 'post',
						url: 'cash_deposit/cancel', 
						data: this.formItem
					}).then ((res) => {
						
						this.cancelLoading = false

						this.$toast.clear()
						
						if (res.success) {
							this.$toast.success(this.translate('deposit_successfully_cancelled'));

							this.loadData()
						} else {
							this.$dialog.alert({
								message: res.error_text
							})
						}
					})
				}).catch(() => {

				})
			},
			imageUpload() {
				if (window.JsObject != null) {
					window.JsObject.normalImageUpload();
				}
				
				if (window.webkit != null) {
					window.webkit.messageHandlers.jsToOcWithPrams.postMessage({"camera": "normal"});
				}
			},
			set_pic(path) {
				this.formItem.image = path
				
				this.$axios({
					method: 'post',
					url: 'cash_deposit/resize', 
					data: this.formItem
				}).then ((res) => {
					if (res.success) {
						this.image = res.data.preview
					} else {
						this.$dialog.alert({
							message: res.error_text
						})
					}
				})
			},
			submitImage() {

				if (this.formItem.image == '') {
					this.$dialog.alert({
						message: this.translate('please_upload_picture_receipt')
					})

					return false
				}

				this.$dialog.confirm({
					message: this.translate('sure_submit_the_receipt')
				}).then(() => {

					this.submitLoading = true

					this.$toast.loading({
						message: this.translate('info_please_wait'),
						forbidClick: true,
					})
					
					this.$axios({
						method: 'post',
						url: 'cash_deposit/update', 
						data: this.formItem
					}).then ((res) => {
						
						this.submitLoading = false

						this.$toast.clear()
						
						if (res.success) {
							this.$toast.success(this.translate('upload_successfully_wait_staff'));
						} else {
							this.$dialog.alert({
								message: res.error_text
							})
						}
					})
				})
			}
		}
	}
</script>